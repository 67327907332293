import { IconsProvider, SpiritToastBarProps, ToastBar } from '@lmc-eu/spirit-web-react';
import React, { ReactNode, useState } from 'react';
import { alertIcons } from '@alertIcons';

interface Props extends SpiritToastBarProps {
  children: ReactNode;
}

const FlashMessageBar = ({ children, ...props }: Props) => {
  const [open, setOpen] = useState<boolean>(true);

  return (
    <IconsProvider value={alertIcons}>
      <ToastBar hasIcon isDismissible onClose={() => setOpen(false)} isOpen={open} {...props}>
        {children}
      </ToastBar>
    </IconsProvider>
  );
};

export default FlashMessageBar;
