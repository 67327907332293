import React, { forwardRef } from 'react';
import sanitizeHtml from 'sanitize-html';

interface Props extends React.ComponentPropsWithoutRef<'div'> {
  as?: 'div' | 'span';
  htmlString: string;
}

const sanitizerOptions = {
  allowedTags: sanitizeHtml.defaults.allowedTags.concat(['ul', 'br', 'li', 'strong', 'a', 'p', 'span', 'ol', 'i']),
  allowedAttributes: {
    a: ['href', 'id', 'rel', 'type', 'name', 'target'],
    ol: ['type'],
    span: ['class'],
    strong: ['class'],
    '*': ['data-cy'],
  },
  parser: {
    lowerCaseAttributeNames: false,
  },
};

const HtmlParser = forwardRef<HTMLDivElement, Props>(({ as = 'div', htmlString, ...divProps }, ref) =>
  as === 'div' ? (
    <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(htmlString, sanitizerOptions) }} {...divProps} ref={ref} />
  ) : (
    <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(htmlString, sanitizerOptions) }} {...divProps} ref={ref} />
  ),
);

export default HtmlParser;
