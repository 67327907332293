export const alertIcons: { [key: string]: string } = {
  success:
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="currentColor"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M17.704 9.21051L10.4809 16.3649L6.34277 12.7535L7.65784 11.2466L10.3948 13.6352L16.2966 7.78955L17.704 9.21051Z" fill="currentColor"></path>',
  cross:
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M7.00019 8.4144L11.9499 13.3642L13.3642 11.9499L8.4144 7.00019L13.3642 2.05044L11.9499 0.63623L7.00019 5.58598L2.05044 0.63623L0.63623 2.05044L5.58598 7.00019L0.63623 11.9499L2.05044 13.3642L7.00019 8.4144Z" fill="currentColor"></path>',
  informative:
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z" fill="currentColor"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M15 18L11 18L11 12L9 12L9 10L13 10L13 16L15 16L15 18ZM13 6L13 8L11 8L11 6L13 6Z" fill="currentColor"></path>',
  danger:
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z" fill="currentColor"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M11 14L11 6L13 6L13 14L11 14ZM11 18L11 16L13 16L13 18L11 18Z" fill="currentColor"></path>',
  warning:
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z" fill="currentColor"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M11 14L11 6L13 6L13 14L11 14ZM11 18L11 16L13 16L13 18L11 18Z" fill="currentColor"></path>',
  info: '<path fill-rule="evenodd" clip-rule="evenodd" d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z" fill="currentColor"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M15 18L11 18L11 12L9 12L9 10L13 10L13 16L15 16L15 18ZM13 6L13 8L11 8L11 6L13 6Z" fill="currentColor"></path>',
  'check-plain':
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="currentColor"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M17.704 9.21051L10.4809 16.3649L6.34277 12.7535L7.65784 11.2466L10.3948 13.6352L16.2966 7.78955L17.704 9.21051Z" fill="currentColor"></path>',
  close:
    '<path d="M18.3 5.70997C17.91 5.31997 17.28 5.31997 16.89 5.70997L12 10.59L7.11 5.69997C6.72 5.30997 6.09 5.30997 5.7 5.69997C5.31 6.08997 5.31 6.71997 5.7 7.10997L10.59 12L5.7 16.89C5.31 17.28 5.31 17.91 5.7 18.3C6.09 18.69 6.72 18.69 7.11 18.3L12 13.41L16.89 18.3C17.28 18.69 17.91 18.69 18.3 18.3C18.69 17.91 18.69 17.28 18.3 16.89L13.41 12L18.3 7.10997C18.68 6.72997 18.68 6.08997 18.3 5.70997Z" fill="currentColor"></path>',
};
