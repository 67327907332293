import classNames from 'classnames';
import React from 'react';
import LogoSvg from '@icons/logo-clean.svg';
import classes from './Logo.module.scss';

interface Props extends React.ComponentPropsWithoutRef<'div'> {}

const Logo = ({ className, ...divProps }: Props) => (
  <div {...divProps} className={classNames(classes.Logo, className)}>
    <LogoSvg />
  </div>
);

export default Logo;
