import { gql } from '@apollo/client';

export const LOGGED_IN_USER = gql`
  query LoggedInUser {
    loggedInUser {
      email
      isB2bContactPerson
    }
  }
`;

export const USER_IDENTIFICATION_HASH = gql`
  query UserIdentificationHash {
    userIdentificationHash
  }
`;

export const USER_DATA_FOR_INTERCOM = gql`
  query UserDataForIntercom {
    userDataForIntercom {
      userId
      hmac
      name
      email
      isRegularContactPerson
      isSalesContactPerson
      is_b2c
      isB2bFromDate
      companies {
        company_id
        name
        plan
        contract_active_from_date
        contract_active_to_date
        ordinal_number_of_consecutive_basic_contract_years
        ordinal_number_of_consecutive_medium_contract_years
        ordinal_number_of_consecutive_premium_contract_years
        count_of_reviews
        count_of_comments
        count_of_employees
        reviews_to_employees_percentage
        comments_to_employees_percentage
        count_of_comment_reactions
        count_of_job_descriptions
        number_of_photos_with_missing_title
        number_of_photos_with_missing_description
        last_review_date
        has_about_us
        has_pinned_comment
        number_of_photos_or_videos
      }
    }
  }
`;

export const COOKIE_NAMES_BY_CATEGORY_FOR_CURRENT_USER = gql`
  query CookieNamesByCategoryForCurrentUser {
    cookieNamesByCategoryForCurrentUser {
      necessary
      ad
      analytics
      functionality
      personalization
    }
  }
`;

export const SET_PASSWORD_TOKEN = gql`
  query SetPasswordToken($token: String!) {
    setPasswordToken(token: $token) {
      isValid
      email
      targetPath
    }
  }
`;

export const LOGIN_FORM_CSRF_TOKEN = gql`
  query LoginFormCsrfToken {
    loginFormCsrfToken
  }
`;
