import { encode } from 'querystring';

export const getUrlWithParameters = (baseUrl: string, queryParameters: { [key: string]: any }) => {
  let urlParameters = '';

  if (Object.keys(queryParameters).length > 0) {
    urlParameters = `?${encode(queryParameters)}`;
  }

  return `${baseUrl}${urlParameters}`;
};
