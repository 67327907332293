import { gql } from '@apollo/client';

export const FLASH_MESSAGES = gql`
  query FlashMessages {
    flashMessages {
      type
      message
    }
  }
`;
