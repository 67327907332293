import { useCallback, useEffect, useState } from 'react';

export const useActiveBreakpoint = () => {
  const [currentBreakpoint, setCurrentBreakpoint] = useState<string | null>(null);

  const handleActiveBreakpoint = useCallback(() => {
    if (typeof window !== 'undefined') {
      const bodyElement = document.querySelector('body');
      if (bodyElement != null) {
        const valueFromBodyContent = window.getComputedStyle(bodyElement, ':before').content;
        const breakpointName = valueFromBodyContent.replace(/"/g, '');
        setCurrentBreakpoint(breakpointName);
      }
    }
  }, [setCurrentBreakpoint]);

  useEffect(() => {
    handleActiveBreakpoint();
    window.addEventListener('resize', handleActiveBreakpoint);

    return () => window.removeEventListener('resize', handleActiveBreakpoint);
  }, [handleActiveBreakpoint]);

  const isMobile = currentBreakpoint === 'mobile';
  const isTablet = currentBreakpoint === 'tablet';
  const isDesktop = currentBreakpoint === 'desktop';

  return { currentBreakpoint, isMobile, isTablet, isDesktop };
};
