export const getSiteName = (): string => {
  if (typeof window !== 'undefined') {
    switch (window.location.hostname) {
      case 'www.atmoskop.cz':
        return 'Atmoskop.cz';
      case 'www.atmoskop.pl':
        return 'Atmoskop.pl';
      default:
        return 'Atmoskop.cz';
    }
  }

  return 'Atmoskop.cz';
};
